import { Link } from 'gatsby';
import tw, { styled } from 'twin.macro';
import { Inner } from 'components';
import { useEffect, useRef } from 'react';

interface EcospherePage {
  slug: string;
  title: string;
}

interface BreadCrumb {
  href: string;
  title: string;
}

const HorizontalScrollNav = styled.ul`
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  ${tw`flex pb-8 -mb-12 pl-5`}
  ${tw`lg:pb-0 lg:mb-0 md:pl-8 lg:pl-0 lg:overflow-auto`}
`;

const ScrollbarHide = styled.nav`
  ${tw`overflow-hidden`}
`;

const BreadCrumbs = styled.ol`
  ${tw`flex mt-8 text-xs px-5 md:px-8 lg:px-0`}

  li + li {
    ${tw`ml-2`}
  }
`;

function EcospheresNav({
  pages,
  breadcrumbs,
}: {
  pages: EcospherePage[];
  breadcrumbs?: BreadCrumb[];
}) {
  const navRef = useRef(null);
  const activeItemRef = useRef(null);
  const lastCrumb = breadcrumbs?.[breadcrumbs.length - 1];

  useEffect(() => {
    const centerActiveElInWindow = () => {
      const navEl: HTMLUListElement | null = navRef.current;

      if (navEl && activeItemRef.current) {
        const nav: HTMLUListElement = navEl;
        const activeEl: HTMLLIElement = activeItemRef.current;
        const activeElW = activeEl.getBoundingClientRect().width;
        const center = window.innerWidth / 2;
        const to = activeEl.offsetLeft - center + activeElW / 2;

        nav.scrollTo(Math.round(to), 0);
      }
    };

    centerActiveElInWindow();
  }, []);

  return (
    <Inner tw="pt-32 sm:pt-44 md:pt-52 lg:px-20 2xl:px-0">
      <ScrollbarHide>
        <HorizontalScrollNav ref={navRef}>
          {pages.map(({ slug, title }) => (
            <li key={slug}>
              <Link
                ref={(lastCrumb?.href === `/${slug}` && activeItemRef) || null}
                to={`/${slug}`}
                activeStyle={tw`opacity-100 font-bold`}
                tw="opacity-50 mr-6 py-4 inline-block lg:py-0"
              >
                {title}
              </Link>
            </li>
          ))}
        </HorizontalScrollNav>
      </ScrollbarHide>

      {breadcrumbs && (
        <BreadCrumbs>
          {breadcrumbs.map((crumb, i) => (
            <li
              key={crumb.href}
              css={[i === breadcrumbs.length - 1 ? tw`font-bold` : tw`font-thin`]}
            >
              <Link to={crumb.href} title={crumb.title}>
                {crumb.title}
              </Link>

              {i < breadcrumbs.length - 1 && <span css={tw`ml-2`}>&rarr;</span>}
            </li>
          ))}
        </BreadCrumbs>
      )}
    </Inner>
  );
}

export default EcospheresNav;
